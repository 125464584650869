<template>
    <a href="#"
       v-if="inputVal.article_id"
       @click="$root.$children[0].openModal('article-modal', {id: inputVal.article_id})"
    >{{inputVal.sku || '-'}}<font-awesome-icon class="ml-1" icon="external-link-alt" /></a>
    <div v-else>{{inputVal.sku}}</div>
</template>

<script>
export default {
    name: 'SkuColumn',
    props: {
        value: [String, Number, Object],
        savePosition: [Function]
    },
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    }
}
</script>